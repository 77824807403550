import { getPageNameFromUrl } from "utils/misc";
import { EventName, EventProperty, EventType, Platform } from "./constants";
import { EventData, InitType, EventTypeData } from "./types";
import { getPlatformInfo } from "./utils";

const HOST = `${process.env.NEXT_PUBLIC_ANALYTICS_URL}/eventserver/api/publish/analytics-events`;

class RnaAnalytics {
  private analyticsData = {
    appInfo: {
      storeId: '',
      sessionId: '',
      userId: '',
      platform: '',
      os: '',
      osVersion: '',
      deviceInfo: '',
      appVersion: '',
      ip: '',
    },
    eventList: [],
  }

  constructor () {
    this.setClintIP();
  }

  /**
   * Get event data
   */
  private getEventData({ eventName, eventType, action, eventData }: EventData) {
    const eventProperty = {
      ...eventData,
      ...(eventData?.ext && { [EventProperty.Extras]: JSON.stringify(eventData.ext) }),
      ...(eventData?.entity_typ && {[EventProperty.EntityType]: eventData.entity_typ}),
      ...((eventData?.entity_id || eventData?.entity_id === 0) && {[EventProperty.EntityID]: eventData.entity_id}),
      [EventProperty.Timestamp]: Date.now(),
      [EventProperty.EventType]: eventType,
      [EventProperty.Action]: action,
    };

    return { eventName, eventProperty };
  }

  private async setClintIP() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      this.analyticsData.appInfo.ip = data.ip;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Common event properties
   * @returns baseEvents object
   */
  private getBaseEvents() {
    return {
      [EventProperty.StoreId]: this.analyticsData.appInfo.storeId,
      [EventProperty.SessionId]: this.analyticsData.appInfo.sessionId,
      [EventProperty.UserId]: this.analyticsData.appInfo.userId,
      [EventProperty.Platform]: this.analyticsData.appInfo.platform,
      [EventProperty.OS]: this.analyticsData.appInfo.os,
      [EventProperty.OSVersion]: this.analyticsData.appInfo.osVersion,
      [EventProperty.DeviceInfo]: this.analyticsData.appInfo.deviceInfo,
      [EventProperty.AppVersion]: this.analyticsData.appInfo.appVersion,
      [EventProperty.IP]: this.analyticsData.appInfo.ip,
      [EventProperty.PageUrl]: window.location.href,
      [EventProperty.SourcePageUrl]: window.sessionStorage.getItem('prevUrl') || '',
      [EventProperty.PageName]: getPageNameFromUrl(window.location.href),
      [EventProperty.SourcePageName]: getPageNameFromUrl(window.sessionStorage.getItem('prevUrl') || ''),
      [EventProperty.AppSessionId]: window.sessionStorage.getItem('appSessionId') || '',
    }
  }

  /**
   * Initialize RNA analytics
   */
  init({ nativeAppVersion = '', ...appInfo }: InitType) {
    const platformInfo = getPlatformInfo();
    const devicePlatform = nativeAppVersion ? Platform.NativeApp : platformInfo?.device?.type || Platform.Desktop;
    const appVersion = `${nativeAppVersion ? nativeAppVersion+'/' : ''}${platformInfo?.browser?.name || ''}/${platformInfo?.browser?.version || ''}`;

    const data = {
      ...this.analyticsData.appInfo,
      storeId: appInfo?.storeId || '',
      sessionId: appInfo?.sessionId || '',
      userId: appInfo?.userId || '',
      platform: devicePlatform,
      os: platformInfo?.os?.name || '',
      osVersion: platformInfo?.os?.version || '',
      deviceInfo: `${platformInfo?.device?.vendor || ''}_${platformInfo?.device?.model || ''}`,
      appVersion: appVersion,
    };

    this.analyticsData.appInfo = data;
  }

  /**
   * Add custom events
   */
  event({ eventName, eventType, action, eventData }: EventData) {
    const evt = this.getEventData({ eventName, eventType, action, eventData });

    // @ts-ignore
    this.analyticsData.eventList.push(evt);

    return this;
  }

  /**
   * Add Impression Event
   */
  impressionEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ImpressionEvent, eventType: EventType.Impression, action, eventData });
  }

  /**
   * Add Click Event
   */
  clickEvent({ action, eventData }: EventTypeData) {
    return this.event({ eventName: EventName.ClickEvent, eventType: EventType.Click, action, eventData });
  }

  /**
   * Send event
   */
  async send() {
    try {
      const payload: { [key: string]: any } = {};
      const baseEvents = this.getBaseEvents();

      this.analyticsData.eventList.map((event: any) => {
        if (payload[event.eventName]) payload[event.eventName].push(event.eventProperty);
        else payload[event.eventName] = [{...event.eventProperty}];
      });

      // Reset event list
      this.analyticsData.eventList = [];

      await fetch(HOST, {
        headers: {
          ...baseEvents,
          'content-type': 'application/json'
        },
        body: JSON.stringify(payload),
        method: 'POST',
      })
    } catch (err) {
      this.analyticsData.eventList = [];
      console.error(err);
    }
  }
}

const rnaAnalytics = new RnaAnalytics();
Object.freeze(rnaAnalytics);

export default rnaAnalytics;
