import usCountryIcon from "assets/US.svg";
import indiaCountryIcon from "assets/India.svg";

export enum CountryCode {
  ALL = 'ALL',
  IN = 'IN',
  US = 'US',
}

export const COUNTRY_CODE = {
  all: CountryCode.ALL,
  india: CountryCode.IN,
  us: CountryCode.US,
}

export const COUNTRY = {
  india: { label: "India", icon: indiaCountryIcon, code: COUNTRY_CODE.india },
  us: { label: "US", icon: usCountryIcon, code: COUNTRY_CODE.us }, 
}

export const COUNTRY_KEY = {
  [COUNTRY_CODE.india]: { label: "India", icon: indiaCountryIcon },
  [COUNTRY_CODE.us]: { label: "US", icon: usCountryIcon }, 
}
