import {COOKIES, getCookie, getCookieDomain} from "./cookies";
import {NextPageContext} from "next";

export const isUserLoggedIn = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return !!getCookie(ctx, COOKIES.recSession, domain) || !! getCookie(ctx, COOKIES.xSessionId, domain);
};

export const userId = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return getCookie(ctx, COOKIES.recSession, domain) ? JSON.parse(getCookie(ctx, COOKIES.recSession, domain)).userId : 0;
};

export const authToken = (ctx?: Partial<NextPageContext> | null | undefined) => {
  return getCookie(ctx, COOKIES.recSession) ? JSON.parse(getCookie(ctx, COOKIES.recSession)).authToken : '';
};

export const sessionId = (ctx?: Partial<NextPageContext> | null | undefined) => {
  return getCookie(ctx, COOKIES.xSessionId) ? getCookie(ctx, COOKIES.xSessionId) : '';
};


export const sellerStoreId = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  if(isStaff(ctx)){
    return getCookie(ctx, COOKIES.staffConfig, domain) ? JSON.parse(getCookie(ctx, COOKIES.staffConfig, domain)).owner : 0;
  }
  return getCookie(ctx, COOKIES.recSession, domain) ? JSON.parse(getCookie(ctx, COOKIES.recSession, domain)).storeId : 0;
};

// export const sellerStoreId = () => '6031360120';

export const isStaff = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  return !!getCookie(ctx, COOKIES.staffConfig, domain);
};

export const staffEmail = (ctx?: Partial<NextPageContext> | null | undefined) => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  if(isStaff(ctx)){
    return getCookie(ctx, COOKIES.staffConfig, domain) ? JSON.parse(getCookie(ctx, COOKIES.staffConfig, domain)).email : "";
  }
  return "";
};

export const availablePermissions = (ctx?: Partial<NextPageContext> | null | undefined): string[] => {
  const domain = ctx ? getCookieDomain(ctx?.req?.headers?.host || "") : '';
  if(isStaff(ctx)){
    return getCookie(ctx, COOKIES.staffConfig, domain) ? JSON.parse(getCookie(ctx, COOKIES.staffConfig, domain)).roles : [];
  }
  return [];
};