import type {AppContext, AppProps} from 'next/app'
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import App from "next/app";
import {v4} from "uuid";
import Head from "next/head";
import NProgress from 'nprogress';
import {ReactNode, useEffect, useMemo} from "react";
import '/styles/globals.css'
import '/styles/nprogress.css'
import '/styles/date-range.css';
import '/styles/slick-carousel.css';
import '/styles/dropzone.css';
import {COOKIES, getCookie, SESSION_KEY, setCookie} from "utils/cookies";
import {wrapper, store, reduxContext} from "store";
import {Provider} from "react-redux";
import {useDispatch} from 'react-redux'
import {getShopifyStoreData, getCloutUnreadPostStatus} from 'actions/usersAction'
import {bindActionCreators} from 'redux';
import { isUserLoggedIn } from "utils/auth";
import 'react-loading-skeleton/dist/skeleton.css'
import { CountryPreference } from 'types';
import logger from 'utils/logger';
import AppAnalytics from 'components/AppAnalytics';

const ToastContainer = dynamic(() => import('components/ToastContainer/_default/ToastContainerV2'));
const DefaultLayout = dynamic(() => import('components/Layout/DefaultLayout/_default/DefaultLayout'));

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement, pageProps?: any) => ReactNode;
};

type O1AppProps = AppProps & {
  themeId: number;
  Component: NextPageWithLayout; 
  userCountryPreference: CountryPreference;
}

const getDefaultLayout = (page: React.ReactElement, pageProps: any) => {
  return (
    <DefaultLayout pageProps={pageProps}>{page}</DefaultLayout>
  );
}

function MyApp({Component, pageProps, router, userCountryPreference }: O1AppProps) {
  useEffect(() => {
    window.addEventListener('load', () => {
      if ('serviceWorker' in navigator) { // Is service worker available?
        navigator.serviceWorker.register('/serviceWorker.js')
        .then(() => { console.log('Service worker registered!'); })
        .catch((error) => { console.warn('Error registering service worker:', error); });
      }
    });
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      window.sessionStorage.setItem(SESSION_KEY.prevUrl, window.location.href);
      NProgress.configure({showSpinner: false}).start();
    })
    router.events.on("routeChangeComplete", () => {
      NProgress.done();
    })
    router.events.on("routeChangeError", () => {
      NProgress.done();
    })
  }, [router])

  const dispatch = useDispatch();

  const boundActionCreators = useMemo(
    () => bindActionCreators({
      getShopifyStoreData,
      getCloutUnreadPostStatus,
    }, dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (isUserLoggedIn()) {
      boundActionCreators.getShopifyStoreData();
      boundActionCreators.getCloutUnreadPostStatus();
    }
  }, [boundActionCreators]);

  const getLayout = Component.getLayout ?? getDefaultLayout;

  return (
    <div>
      <AppAnalytics />
      <Head>
        <title>Clout Roposo App</title>
        <meta name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=1.0"/>
        <meta 
          name="theme-color" 
          content="#FFFFFF"
        />
      </Head>
      <Provider
        store={store}
        // @ts-ignore
        context={reduxContext}
      >
        {getLayout(
          <Component 
            {...pageProps} 
            userCountryPreference={userCountryPreference} 
          />, 
          {...pageProps, userCountryPreference, router}
        )}
        <ToastContainer position="bottom-center" />
      </Provider>
    </div>
  )
}

MyApp.getInitialProps = async (appCtx: AppContext) => {
  if (typeof window === "undefined") {
    logger.info(`[Page][Request] GET ${appCtx.ctx.req?.url} ${JSON.stringify(appCtx.ctx.req?.headers)}`);
  }
  const appProps = await App.getInitialProps(appCtx);
  let deviceId = getCookie(appCtx.ctx, COOKIES.deviceId);
  if (!deviceId || appCtx.router.query.userid) {
    deviceId = v4();
    setCookie(appCtx.ctx, COOKIES.deviceId, appCtx.router.query.userid?.toString() || deviceId.toString(), null, 30);
  }

  return {
    ...appProps, 
    userCountryPreference: JSON.parse(getCookie(appCtx.ctx, COOKIES.countryPreference) || '{}')
  };
}

export default wrapper.withRedux(MyApp);
